<template>
    <div>
        <div class="fileupload">
            <div v-if="variantAttribute" class="load-block">
                <span class="file-upload-trigger" @click="openModalFileUpload"><i class="icon icon-analytics"></i> <b>{{ getLocText('upload_file') }}</b></span>
            </div>
            <b-tabs class="inside-content">
                <b-tab active>
                    <template #title>
                        {{ getFileTypeByCode('1001') }} (<span>{{(contractFiles && contractFiles.length > 0) ? contractFiles.length : 0}}</span>)
                    </template>
                    <!-- 1 item file -->
                    <div class="file-info" v-for="(cF, index) in contractFiles" :key="cF.id">
                        <p class="date">{{cF.createdTime}}</p>
                        <p class="name">
                            <file-icons :filename="cF.file_name"></file-icons>
                            <span @click="previewFile(cF.file_name)" 
                                    class="fileupload_file-name" 
                                    :title="getTitleText(cF.file_name)">{{cF.file_name}}</span>
                        </p>
                        <p class="size">{{cF.size}}.</p>
                        <p 
                            class="delete action_btn" 
                            @click="downloadFile(cF.file_name, cF.id)"
                        >   
                            <b-spinner v-if="getIsDownloading(cF.id)" small variant="success" label="Spinning" class="spinner"></b-spinner>
                            <i v-if="!getIsDownloading(cF.id)" class="icon icon-download"></i> 
                            <span>{{ getLocText('download_file') }}</span>
                        </p>
                        <p v-if="showDeleteLinks || variantAttribute" class="delete action_btn" @click="delFile(cF.category, cF.id, index, cF.file_name, contractFiles)">
                            <i class="icon icon-close"></i>
                            <span>{{ getLocText('delete') }}</span>
                        </p>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        {{ getFileTypeByCode('1002') }} (<span>{{(comOfferFiles && comOfferFiles.length > 0) ? comOfferFiles.length : 0}}</span>)
                    </template>
                    <!-- 1 item file -->
                    <div class="file-info" v-for="(cF, index) in comOfferFiles" :key="cF.id">
                        <p class="date">{{cF.createdTime}}</p>
                        <p class="name">
                            <file-icons :filename="cF.file_name"></file-icons>
                            <span @click="previewFile(cF.file_name)"
                                    class="fileupload_file-name" 
                                    :title="getTitleText(cF.file_name)">{{cF.file_name}}</span>
                        </p>
                        <p class="size">{{cF.size}}.</p>
                        <p 
                            class="delete action_btn" 
                            @click="downloadFile(cF.file_name, cF.id)"
                        >   
                            <b-spinner v-if="getIsDownloading(cF.id)" small variant="success" label="Spinning" class="spinner"></b-spinner>
                            <i v-if="!getIsDownloading(cF.id)" class="icon icon-download"></i> 
                            <span>{{ getLocText('download_file') }}</span>
                        </p>
                        <p v-if="showDeleteLinks || variantAttribute" class="delete" @click="delFile(cF.category, cF.id, index, cF.file_name, comOfferFiles)">
                            <i class="icon icon-close"></i>
                            <span>{{ getLocText('delete') }}</span>
                        </p>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        {{ getFileTypeByCode('1003') }} (<span>{{(actFiles && actFiles.length > 0) ? actFiles.length : 0}}</span>)
                    </template>
                    <!-- 1 item file -->
                    <div class="file-info" v-for="(cF, index) in actFiles" :key="cF.id">
                        <p class="date">{{cF.createdTime}}</p>
                        <p class="name">
                            <file-icons :filename="cF.file_name"></file-icons>
                            <span @click="previewFile(cF.file_name)"
                                    class="fileupload_file-name" 
                                    :title="getTitleText(cF.file_name)">{{cF.file_name}}</span>
                        </p>
                        <p class="size">{{cF.size}}.</p>
                        <p 
                            class="delete action_btn" 
                            @click="downloadFile(cF.file_name, cF.id)"
                        >   
                            <b-spinner v-if="getIsDownloading(cF.id)" small variant="success" label="Spinning" class="spinner"></b-spinner>
                            <i v-if="!getIsDownloading(cF.id)" class="icon icon-download"></i> 
                            <span>{{ getLocText('download_file') }}</span>
                        </p>
                        <p v-if="showDeleteLinks || variantAttribute" class="delete" @click="delFile(cF.category, cF.id, index, cF.file_name, actFiles)">
                            <i class="icon icon-close"></i>
                            <span>{{ getLocText('delete') }}</span>
                        </p>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        {{ getFileTypeByCode('1004') }} (<span>{{(estimateFiles && estimateFiles.length > 0) ? estimateFiles.length : 0}}</span>)
                    </template>
                    <!-- 1 item file -->
                    <div class="file-info" v-for="(cF, index) in estimateFiles" :key="cF.id">
                        <p class="date">{{cF.createdTime}}</p>
                        <p class="name">
                            <file-icons :filename="cF.file_name"></file-icons>
                            <span @click="previewFile(cF.file_name)"
                                    class="fileupload_file-name" 
                                    :title="getTitleText(cF.file_name)">{{cF.file_name}}</span>
                        </p>
                        <p class="size">{{cF.size}}.</p>
                        <p 
                            class="delete action_btn" 
                            @click="downloadFile(cF.file_name, cF.id)"
                        >   
                            <b-spinner v-if="getIsDownloading(cF.id)" small variant="success" label="Spinning" class="spinner"></b-spinner>
                            <i v-if="!getIsDownloading(cF.id)" class="icon icon-download"></i> 
                            <span>{{ getLocText('download_file') }}</span>
                        </p>
                        <p v-if="showDeleteLinks || variantAttribute" class="delete" @click="delFile(cF.category, cF.id, index, cF.file_name, estimateFiles)">
                            <i class="icon icon-close"></i>
                            <span>{{ getLocText('delete') }}</span>
                        </p>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        {{ getFileTypeByCode('1005') }} (<span>{{(planFiles && planFiles.length > 0) ? planFiles.length : 0}}</span>)
                    </template>
                    <!-- 1 item file -->
                    <div class="file-info" v-for="(cF, index) in planFiles" :key="cF.id">
                        <p class="date">{{cF.createdTime}}</p>
                        <p class="name">
                            <file-icons :filename="cF.file_name"></file-icons>
                            <span @click="previewFile(cF.file_name)"
                                    class="fileupload_file-name" 
                                    :title="getTitleText(cF.file_name)">{{cF.file_name}}</span>
                        </p>
                        <p class="size">{{cF.size}}.</p>
                        <p 
                            class="delete action_btn" 
                            @click="downloadFile(cF.file_name, cF.id)"
                        >   
                            <b-spinner v-if="getIsDownloading(cF.id)" small variant="success" label="Spinning" class="spinner"></b-spinner>
                            <i v-if="!getIsDownloading(cF.id)" class="icon icon-download"></i> 
                            <span>{{ getLocText('download_file') }}</span>
                        </p>
                        <p v-if="showDeleteLinks || variantAttribute" class="delete" @click="delFile(cF.category, cF.id, index, cF.file_name, planFiles)">
                            <i class="icon icon-close"></i>
                            <span>{{ getLocText('delete') }}</span>
                        </p>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        {{ getFileTypeByCode('1006') }} (<span>{{(conclusionFiles && conclusionFiles.length > 0) ? conclusionFiles.length : 0}}</span>)
                    </template>
                    <!-- 1 item file -->
                    <div class="file-info" v-for="(cF, index) in conclusionFiles" :key="cF.id">
                        <p class="date">{{cF.createdTime}}</p>
                        <p class="name">
                            <file-icons :filename="cF.file_name"></file-icons>
                            <span @click="previewFile(cF.file_name)"
                                    class="fileupload_file-name" 
                                    :title="getTitleText(cF.file_name)">{{cF.file_name}}</span>
                        </p>
                        <p class="size">{{cF.size}}.</p>
                        <p 
                            class="delete action_btn" 
                            @click="downloadFile(cF.file_name, cF.id)"
                        >   
                            <b-spinner v-if="getIsDownloading(cF.id)" small variant="success" label="Spinning" class="spinner"></b-spinner>
                            <i v-if="!getIsDownloading(cF.id)" class="icon icon-download"></i> 
                            <span>{{ getLocText('download_file') }}</span>
                        </p>
                        <p v-if="showDeleteLinks || variantAttribute" class="delete" @click="delFile(cF.category, cF.id, index, cF.file_name, conclusionFiles)">
                            <i class="icon icon-close"></i>
                            <span>{{ getLocText('delete') }}</span>
                        </p>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        {{ getFileTypeByCode('1007') }} (<span>{{(calculationFiles && calculationFiles.length > 0) ? calculationFiles.length : 0}}</span>)
                    </template>
                    <!-- 1 item file -->
                    <div class="file-info" v-for="(cF, index) in calculationFiles" :key="cF.id">
                        <p class="date">{{cF.createdTime}}</p>
                        <p class="name">
                            <file-icons :filename="cF.file_name"></file-icons>
                            <span @click="previewFile(cF.file_name)"
                                    class="fileupload_file-name" 
                                    :title="getTitleText(cF.file_name)">{{cF.file_name}}</span>
                        </p>
                        <p class="size">{{cF.size}}.</p>
                        <p 
                            class="delete action_btn" 
                            @click="downloadFile(cF.file_name, cF.id)"
                        >   
                            <b-spinner v-if="getIsDownloading(cF.id)" small variant="success" label="Spinning" class="spinner"></b-spinner>
                            <i v-if="!getIsDownloading(cF.id)" class="icon icon-download"></i> 
                            <span>{{ getLocText('download_file') }}</span>
                        </p>
                        <p v-if="showDeleteLinks || variantAttribute" class="delete" @click="delFile(cF.category, cF.id, index, cF.file_name, calculationFiles)">
                            <i class="icon icon-close"></i>
                            <span>{{ getLocText('delete') }}</span>
                        </p>
                    </div>
                </b-tab>
                <template #tabs-end>
                    <li role="presentation" class="nav-item align-self-center">
                        <a href="#" class="nav-link download-all" @click="downloadAllFile(false)">{{ getLocText('download_all') }}
                            <span>({{(files && files.length > 0) ? files.length : 0}})</span></a>
                    </li>
                    <li v-if="variantAttribute && showDeleteAll && files.length" role="presentation" class="nav-item align-self-cente">
                        <p class="nav-link delete-all" @click="onDelAllFiles">
                            <i v-if="!deletigFiles" class="icon icon-close"></i>
                            <b-spinner v-if="deletigFiles" small variant="danger" label="Spinning"></b-spinner>
                            {{ getLocText('del_all') }} <span>({{files.length}})</span>
                        </p>
                    </li>
                </template>
            </b-tabs>
        </div>

         <!-- !!! эта модалка используется в форме расчета 149-03 в расшифровках. Учитывать при изменении   -->
        <b-modal ref="modalFileUpload" id="modal-file-upload" modal-class="modal-file-upload" v-model="modalFileUpload" @hidden="closeModal" centered hide-header hide-footer size="sm">
            <b-overlay :show="btnDisabled" rounded="sm">
                <div class="modal-title">{{ getLocText('select_cat') }}</div>
                <b-form-group>
                    <b-form-select v-model="selectedCategory" :options="categoryFiles" text-field="name"></b-form-select>
                </b-form-group>

                <b-form-file id="file"
                            :disabled="!selectedCategory"
                            accept="*.*"
                            v-model="file"
                            @input="uploadFile"
                            multiple
                            :placeholder="getLocText('upload')"
                ></b-form-file>
                <p class="upload-btn-label">({{ getLocText('max_size', {size: 50}) }})</p>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Ax } from '@/utils';
import axios from 'axios';
import store from "../../services/store";
import FileUpdownMixin from './mixins/file-updown-mixin';
import FileIcons from './components/file-icons.vue';

export default {
    name: 'FilesUpdown',
    components: { FileIcons },
    props: {
        header: null,
        load: Boolean,
        variantAttribute: Boolean,
        showDeleteLinks: {
            type: Boolean,
            default: false
        },
        showDeleteAll: {
            type: Boolean,
            default: true
        },
        isPaidTab: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    mixins: [FileUpdownMixin],
    data() {
        return {
            fileFields: [
                {
                    key: 'file_name',
                    label: 'Прикрепленные файлы'
                },
                {
                    key: 'size',
                    label: ''
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            files: [],
            selectedCategory: null,
            contractFiles: [],
            comOfferFiles: [],
            actFiles: [],
            estimateFiles: [],
            planFiles: [],
            conclusionFiles: [],
            calculationFiles: [],
            file: null,
            modalFileUpload: false,
            rowId: null,
            deletigFiles: false,
            btnDisabled: false,
            noPreviewExtentions: [
                '.7z', '.ace', '.arj', '.bin', '.bin', '.cab', '.cab', '.cbr', '.deb', '.exe', '.gz', 
                '.gzip', '.jar', '.one', '.pak', '.pak', '.pak', '.pkg', '.pkg', '.pkg', '.pkg', 
                '.ppt', '.rar', '.rpm', '.sh', '.sib', '.sis', '.sisx', '.sit', '.sitx', '.spl', 
                '.spl', '.tar', '.tar-gz', '.tgz', '.xar', '.zip', '.zip', '.zipx'
            ],
            isFileDownloading: {}
        };
    },

    created() {
        this.loadDict()
        this.$watch('load', this.loadFiles);        
    },

    methods: {
        async delFile(file_type, file_id, index, file_name, array) {
            this.$bvModal.msgBoxConfirm(
                this.getLocText('delete_file'),
                {
                    title: this.getLocText('confirmation'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getLocText('yes'),
                    cancelTitle: this.getLocText('no'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.deleteFile(file_id, index, file_name, array, file_type);
                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.getLocText('delete_err'), error.toString());
                });
        }, // удаление файла

        async deleteFile(file_id, index, file_name, array, file_type) {
            const that = this;
            const deletingFileBody = {
                file_id,
                file_name,
                header: this.header
            }
            try {
                let url = '/api-py/delete-file/';
                if (this.isPaidTab) url = '/api-py/delete-file-paid/';
                await this.$emit('delelteAttachedFile', file_name, file_type);
                const response = await fetch(url, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(deletingFileBody)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {

                    array.splice(index, 1);
                    this.$emit('restetFilesList');
                    const newFiles = [];
                    that.files.forEach((file, index) => {
                        if (file.id !== file_id) {
                            newFiles.push(file)
                            this.$emit('fillFilesList', file);
                        }
                    });
                    that.files = newFiles
                    that.getFiles();
                    that.makeToast('success', this.getLocText('msg'), this.getLocText('file_deleted'));
                    this.$emit('delFile', file_id);
                } else {
                    that.makeToast('danger', this.getLocText('warning'), this.getLocText('delete_err'));
                }
            } catch {
                that.makeToast('danger', this.getLocText('warning'), this.getLocText('delete_err'));
            }
        }, // удаляет файл с хранилища

        onDelAllFiles() {
            this.$bvModal.msgBoxConfirm(
                this.getLocText('delete_all_file'),
                {
                    title: this.getLocText('confirmation'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getLocText('yes'),
                    cancelTitle: this.getLocText('no'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        this.deleteAllFiles();
                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.getLocText('delete_err'), error.toString());
                });
        }, // удаление всех файлов

        async deleteAllFiles() {
            this.deletigFiles = true;
            const idToDel = [];
            this.files.forEach(file => {
                this.$set(file, 'file_hash', this.stringToUnicode(file.category + file.file_name));
                idToDel.push(file.id)
            })
            const data = {files: this.files, header: this.header, showDeleteLinks: this.showDeleteLinks}
            try {
                let url = '/api-py/delete-all-files/';
                if (this.isPaidTab) url = '/api-py/delete-all-files-paid/';
                const response = await fetch(url, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(data)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.files.splice(0);
                    this.contractFiles.splice(0);
                    this.comOfferFiles.splice(0);
                    this.actFiles.splice(0);
                    this.estimateFiles.splice(0);
                    this.planFiles.splice(0);
                    this.conclusionFiles.splice(0);
                    this.calculationFiles.splice(0);
                    idToDel.forEach(file_id => this.$emit('delFile', file_id))
                    this.$emit('delelteAttachedFile');
                    this.$emit('restetFilesList');
                    this.makeToast('success', this.getLocText('msg'), this.getLocText('files_deleted'));
                    this.deletigFiles = false;
                } else {
                    this.makeToast('danger', this.getLocText('warning'), this.getLocText('delete_err'));
                    this.deletigFiles = false;
                }
            } catch {
                this.makeToast('danger', this.getLocText('warning'), this.getLocText('delete_err'));
                this.deletigFiles = false;
            }
        },

       stringToUnicode(string) {
            let chr = '';
            if (string.length === 0) return hash;
            for (let i = 0; i < string.length; i++) {
                chr += string.charCodeAt(i);
            }
            return chr;
        },

        previewFile(fileName) {
            if (this.isUnsupportedExtention(fileName)) return;
            this.$emit('onFilePreview', fileName);
        },

        isUnsupportedExtention(fileName) {
            return this.noPreviewExtentions.includes(this.getFileExten(fileName));
        },

        getIsDownloading(file_id) {
            return this.isFileDownloading[file_id.toString()];
        },

        downloadFile(filename, file_id) {
            if (this.getIsDownloading(file_id)) return;
            const that = this;
            this.$set(this.isFileDownloading, file_id.toString(), true);
            
            let url = '/api-py/download-file/';
            if (this.isPaidTab) url = '/api-py/download-file-paid/';
            Ax(
                {   
                    url: url + filename + '/' + JSON.stringify(that.header),
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);// or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.$set(this.isFileDownloading, file_id.toString(), false);
                },
                (error) => {
                    this.$set(this.isFileDownloading, file_id.toString(), false);
                    that.makeToast('danger', this.getLocText('download_err'), error.toString());
                }
            );
        }, // скачать файл

        downloadAllFile(filesArr = false) {
            const that = this;
            const allFileNames = [];
            const files = filesArr || that.files;
            files.forEach(file => {
                allFileNames.push(file.file_name);
            });

            if (allFileNames && allFileNames.length > 0) {
                this.downloadAllFileReqst(allFileNames);
            }
        }, // скачать архив всех прикрепленных файлов

        downloadAllFileReqst(allFileNames) {
            this.isFileDownloading = true;
            const usrId = store.state.user.sub;
            const downloadData = {
                allFileNames,
                header: this.header
            }
            let url = '/api-py/download-all-file/';
            if (this.isPaidTab) url = '/api-py/download-all-file-paid/';
            Ax(
                {
                    url: url + usrId + '/' + this.header.mode,
                    method: 'POST',
                    data: downloadData,
                    responseType: 'blob'
                },
                async (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.getLocText('all_files')}.zip`);// or any other extension
                    document.body.appendChild(link);
                    link.click();

                    await axios.post('/api-py/delete-zip-file-by-name/' + usrId); // запрос чтобы удалить сгенерированный архив
                },
                async (error) => {
                    this.isFileDownloading = false;
                    that.makeToast('danger', this.getLocText('download_err'), error.toString());
                    await axios.post(delZipUrl + usrId); // запрос чтобы удалить сгенерированный архив
                }
            );
        },

        getFiles() {
            const data = this.files;
            this.$emit('getFiles', data);
        },

        async loadFiles() {
            const that = this;
            if (that.load) {
                this.$emit('restetFilesList');
                that.files.splice(0);
                try {
                    let url = '/api-py/get-files/';
                    if (this.isPaidTab) url = '/api-py/get-files-paid/';
                    const response = await fetch(url + JSON.stringify(that.header));
                    that.files = await response.json();
                    that.contractFiles = [];
                    that.comOfferFiles = [];
                    that.actFiles = [];
                    that.estimateFiles = [];
                    that.planFiles = [];
                    that.conclusionFiles = [];
                    that.calculationFiles = [];
                    that.files.forEach(f => {
                        this.fillArrsByCategory(f);
                    });
                    that.getFiles();
                } catch (error) {
                    that.makeToast('danger', this.getLocText('get_files_err'), error.toString());
                }
            }
        },

        fillArrsByCategory(item) {
            const that = this;
            const category = item.category;
            switch (category) {
                case '1001':
                    that.contractFiles.push(item);
                    break;
                case '1002':
                    that.comOfferFiles.push(item);
                    break;
                case '1003':
                    that.actFiles.push(item);
                    break;
                case '1004':
                    that.estimateFiles.push(item);
                    break;
                case '1005':
                    that.planFiles.push(item);
                    break;
                case '1006':
                    that.conclusionFiles.push(item);
                    break;
                case '1007':
                    that.calculationFiles.push(item);
                    break;
            }
            this.$emit('fillFilesList', item);
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        openModalFileUpload() {
            this.modalFileUpload = !this.modalFileUpload;
            this.rowId = null;
        },

        setRowId(row_id) {
            this.rowId = row_id;
        },

        closeModal() {
            this.selectedCategory = null;
        },

        async uploadFile() {
            if (this.selectedCategory === null) {
                this.makeToast('danger', this.getLocText('msg'), this.getLocText('cat_not_selected'));
                this.file = null;
            } else {
                const newAddedFiles = [];
                const that = this;
                for (let i = 0; i < that.file.length; i++) {
                    const formData = new FormData();
                    formData.append('file', that.file[i], that.file[i].name);
                    this.btnDisabled = true;
                    let url = '/api-py/upload-file/';
                    if (this.isPaidTab) url = '/api-py/upload-file-paid/';
                    await axios.post(url + JSON.stringify(that.header) + '/' + that.selectedCategory,
                        formData,
                        {
                            'Accept': 'application/json',
                            'Content-Type': 'multipart/form-data'
                        }
                    )
                        .then(response => {
                            if (response.data && response.data.existed) {
                                that.makeToast('danger', this.getLocText('msg'), this.getLocText('file_exist', {fileName: that.file[i].name}));
                            } else {
                                const resp = response.data;
                                that.fillArrsByCategory({
                                    id: resp.id,
                                    file_name: that.file[i].name,
                                    size: resp.size,
                                    category: resp.category,
                                    createdTime: resp.createdTime,
                                    new_file: true
                                });
                                that.files.push({
                                    id: resp.id,
                                    file_name: that.file[i].name,
                                    size: resp.size,
                                    category: resp.category,
                                    createdTime: resp.createdTime
                                });
                                newAddedFiles.push({
                                    id: null,
                                    file_id: resp.id,
                                    file_name: that.file[i].name,
                                    file_type: resp.category,
                                    row_id: this.rowId,
                                    createdTime: resp.createdTime,
                                    size: resp.size
                                });
                                that.makeToast('success', this.getLocText('msg'), this.getLocText('upload_success', {fileName: that.file[i].name}));
                            }
                        })
                        .catch((error) => {
                            that.makeToast('danger', this.getLocText('warning'), error.toString());
                        })
                        .finally(() => this.btnDisabled = false)
                }
                this.$emit('getNewFiles', newAddedFiles);
                that.getFiles();
                that.file = null;
            }
        }, // скачать файл

        showModalFileUpload() {
            this.$refs.modalFileUpload.show();
        },
    
        getFileExten(fileName) {
            const lastIndex = fileName.lastIndexOf('.');
            if (lastIndex === -1) {
                return ''; 
            } else {
                return fileName.substring(lastIndex).toLowerCase();
            }
        },

        getTitleText(fileName) {
            if (this.isUnsupportedExtention(fileName)) {
                return this.getLocText('unsupported_format');
            }
            else {
                return this.getLocText('preview_file');
            }
        }
    },
    computed: {
      
    },
    watch: {
        header: function () {
            this.loadFiles();
        }
    }
};
</script>

<style scoped>
.delete-all {
    color: #d34641 !important;
    cursor: pointer;
}
.delete-all i {
    font-size: 0.6rem;
    margin-right: 0.3rem;
}
.upload-btn-label {
    font-size: 0.6rem;
    font-style: italic;
    opacity: 0.6;
}
.fileupload_file-name {
    cursor: pointer;
}
.fileupload .icon-download {
    color: #008000 !important;
    font-weight: bold;
}

.fileupload .action_btn {
    margin-right: 20px;
}

.fileupload .action_btn:active,
.fileupload .action_btn:active * {
    color: #2b3c47;
}

.fileupload .spinner {
    margin-right: 4px;
    height: 12px;
    width: 12px;
}

</style>